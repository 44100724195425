<template>
  <div class="formBox">
    <div style="display: flex; align-items: center">
      <h4 class="formBox_header">基本信息</h4>
    </div>
    <el-form :rules="updatePwdRules" ref="form" :model="form" label-width="120px">
      <el-form-item label="商机封面图" prop="clueImage">
        <div class="cover">
          <div class="img_box">
            <img-big-upload
              :imageShow.sync="form.clueImage"
              @uploadOneImgShow="uploadOneImg"
              @removeImg="removeImg"
            ></img-big-upload>
            <span style="color: #146aff">（建议图片整体尺寸为442*226 px，大小不超过12MB）</span>
          </div>
          <div v-if="form.clueImage">缩略图</div>
          <div v-if="form.clueImage" class="thumbnail">
            <div class="thumbnail_box">
              <img :src="form.clueImage" alt="" />
              <div>图片尺寸：229*131 pxx</div>
              <div>图片大小：4MB</div>
              <div>展示位置：首页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="form.clueImage" alt="" />
              <div>图片尺寸：442*226 px</div>
              <div>图片大小：5MB</div>
              <div>展示位置：文章详情页</div>
            </div>
            <div class="thumbnail_box">
              <img :src="form.clueImage" alt="" />
              <div>图片尺寸：442*226 px</div>
              <div>图片大小：10MB</div>
              <div>展示位置：首页banner区域</div>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="商机状态" prop="clueStage">
        <el-radio-group v-model="form.clueStage">
          <el-radio :label="item.codeName" v-for="item in stageList" :key="item.id">
            {{ item.codeName }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="项目标题" prop="clueName">
        <el-input v-model="form.clueName" placeholder="请输入项目标题"></el-input>
      </el-form-item>
      <el-form-item label="一句话介绍">
        <div class="txtree">
          <el-input
            v-model="form.clueOneDescription"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            placeholder="请输入需求介绍"
            maxlength="100"
            show-word-limit
          />
        </div>
      </el-form-item>
      <el-form-item label="项目需求" prop="clueProjectRequirement">
        <div class="editorbar_box">
          <div class="editBar">
            <Editorbar v-model="form.clueProjectRequirement" :isClear="false" />
          </div>
        </div>
      </el-form-item>
      <el-form-item label="开始日期" prop="clueStartTime">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="form.clueStartTime"
          type="date"
          placeholder="选择开始日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="预计完成日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="form.estimatedCompletionTime"
          type="date"
          placeholder="选择预计完成日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="项目地区" class="informationType">
        <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
          <el-option
            v-for="item in operatingList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <div style="display: inline-block; width: 10px"></div>
        <el-select v-model="form.city" placeholder="请选择市" @change="districtchange">
          <el-option
            v-for="item in operatingLists"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <div style="display: inline-block; width: 10px"></div>
        <el-select v-model="form.district" placeholder="请选择区">
          <el-option
            v-for="item in districtList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="线索类型" prop="clueType">
        <el-radio-group v-model="form.clueType">
          <el-radio :label="item.codeName" v-for="(item, index) in typeList" :key="index">{{
            item.codeName
          }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="供应商要求" prop="supplierRequirement">
        <div class="txtree">
          <el-input
            v-model="form.supplierRequirement"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10 }"
            placeholder="请输入供应商要求"
            maxlength="100"
            show-word-limit
          />
        </div>
      </el-form-item>
      <el-form-item label="预算费用">
        <el-input
          @input="handleInput"
          v-model="form.budget"
          placeholder="请输入预算费用"
          style="width: 200px; margin-right: 10px"
        ></el-input
        >万
      </el-form-item>
      <el-form-item label="押金费用" prop="deposit">
        <el-input
          @input="handleInputDeposit"
          v-model="form.deposit"
          placeholder="请输入押金费用"
          style="width: 200px; margin-right: 10px"
        ></el-input
        >元
      </el-form-item>
      <el-form-item label="线索费用" prop="clueCost">
        <el-input
          @input="handleInputClueCost"
          v-model="form.clueCost"
          placeholder="请输入线索费用"
          style="width: 200px; margin-right: 10px"
        ></el-input
        >元
      </el-form-item>
      <el-form-item label="附件上传">
        <div class="file_flex" v-for="(el, i) in clueFiles" :key="i">
          <div
            class="name_form"
            style="width: 600px; display: flex; justify-content: space-between"
          >
            <el-input v-model="el.name" placeholder="请添加内容附件" />
            <el-upload
              action
              class="upload-demo"
              ref="upload"
              :show-file-list="false"
              :before-upload="beforeUploadprods"
              :http-request="
                (res) => {
                  return uploadprods(res, i)
                }
              "
            >
              <el-button
                style="position: relative; left: -90px"
                slot="trigger"
                size="mini"
                type="primary"
                plain
                >上传附件</el-button
              >
            </el-upload>
          </div>
          <div style="display: flex; font-size: 24px">
            <span v-if="i == 0" style="color: #4e93fb" @click="addUpload"
              ><i class="el-icon-circle-plus"></i
            ></span>
            <span v-if="i > 0" style="color: #ff7575" @click="delUpload(i)"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="图片上传">
        <!-- <UploadImage
          style="margin-right: 10px"
          :imageShow.sync="form.imgUrl1"
          :addTitleShow="false"
          @removeImg="removeImgDian"
          @uploadOneImgShow="uploadOneImgShow"
        /> -->
        <div class="image_flex" v-for="(el, i) in clueAttachmentImageList" :key="i">
          <div>
            <img-big-upload
              :imageShow.sync="el.name"
              @uploadOneImgShow="uploadAttachmentImage"
              @removeImg="removeAttachmentImage"
            ></img-big-upload>
          </div>
          <div style="display: flex; font-size: 24px">
            <span style="color: #4e93fb" v-if="i == 0"
              ><i class="el-icon-circle-plus" @click="addAttachmentImage"></i
            ></span>
            <span style="color: #ff7575; margin-left: 10px" v-if="i > 0"
              ><i class="el-icon-delete" @click="delAttachmentImage(i)"></i
            ></span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="视频上传">
        <div class="cover">
          <div class="img_box" v-if="form.imageAndVideoResults">
            <uploadVideo
              v-bind:videos="form.imageAndVideoResults"
              :videosId="form.videoList"
              @uploadOneImg="uploadOneImgvideo"
              @removeImg="removeImgvideo"
            ></uploadVideo>
          </div>
          <div class="img_box" v-else>
            <uploadVideo
              @uploadOneImg="uploadOneImgvideo"
              @removeImg="removeImgvideo"
            ></uploadVideo>
          </div>
        </div>
      </el-form-item>

      <h4 class="h4_share">客户信息</h4>
      <el-form-item label="客户类型:">
        <el-radio-group v-model="form.companyType">
          <el-radio :label="item.codeName" v-for="item in companyTypeList" :key="item.id">
            {{ item.codeName }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="客户名称:">
        <el-autocomplete
          v-model="form.companyFullName"
          :fetch-suggestions="
            (res, cb) => {
              return querySearchAsyncapts(res, cb)
            }
          "
          placeholder="请搜索付款企业"
          @select="
            (res) => {
              return handleSelectcapts(res)
            }
          "
        >
          <template slot-scope="{ item }">
            <div class="name">
              <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                item.companyFullName
              }}</span>
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="对接人姓名">
        <div class="file_flex" v-for="(el, i) in form.contactPersonList" :key="i">
          <el-input v-model="el.customerContactPerson" placeholder="请输入对接人姓名" />
          <div style="display: flex; font-size: 24px">
            <span v-if="i == 0" style="color: #4e93fb" @click="addCustomerContactPerson"
              ><i class="el-icon-circle-plus"></i
            ></span>
            <span
              v-if="i > 0"
              style="color: #ff7575; margin-left: 10px"
              @click="delCustomerContactPerson(i)"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="职位">
        <div class="file_flex" v-for="(el, i) in form.contactPersonList" :key="i">
          <div>
            <el-select v-model="el.associatePosition" placeholder="请选择职位">
              <el-option
                v-for="(item, index) in zhiweiList"
                :key="index"
                :label="item.codeName"
                :value="item.codeName"
              >
              </el-option>
            </el-select>
            <div style="margin-top: 12px" class="qita" v-if="el.associatePosition === '其他'">
              <el-input v-model="el.otherPosition" placeholder="请输入其它职位名称"></el-input>
            </div>
          </div>
          <div style="display: flex; font-size: 24px">
            <span
              v-if="i > 0"
              style="color: #ff7575; margin-left: 10px"
              @click="delCustomerContactPerson(i)"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="手机号">
        <div class="file_flex" v-for="(el, i) in form.contactPersonList" :key="i">
          <el-input v-model="el.associatePhone" placeholder="请输入手机号" />
          <div style="display: flex; font-size: 24px">
            <span
              v-if="i > 0"
              style="color: #ff7575; margin-left: 10px"
              @click="delCustomerContactPerson(i)"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="QQ号码">
        <div class="file_flex" v-for="(el, i) in form.contactPersonList" :key="i">
          <el-input v-model="el.qq" placeholder="请输入QQ号码" />
          <div style="display: flex; font-size: 24px">
            <span
              v-if="i > 0"
              style="color: #ff7575; margin-left: 10px"
              @click="delCustomerContactPerson(i)"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="微信号码">
        <div class="file_flex" v-for="(el, i) in form.contactPersonList" :key="i">
          <el-input v-model="el.weChatNumber" placeholder="请输入微信号码" />
          <div style="display: flex; font-size: 24px">
            <span
              v-if="i > 0"
              style="color: #ff7575; margin-left: 10px"
              @click="delCustomerContactPerson(i)"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div>
      </el-form-item>

      <h4 class="h4_share">触达途径</h4>
      <el-form-item label="线索来源">
        <el-select v-model="form.clueResource" placeholder="请选择线索来源 ">
          <el-option
            v-for="(item, index) in sourceList"
            :key="index"
            :label="item.codeName"
            :value="item.codeName"
          >
          </el-option>
        </el-select>
        <div style="margin-top: 12px" class="qita" v-if="form.clueResource === '其它'">
          <el-input v-model="form.otherResource" placeholder="请输入其它线索来源"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="渠道名称">
        <el-input v-model="form.clueChannelName" placeholder="请输入渠道名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input v-model="form.clueChannelPhone" placeholder="请输入手机号码"></el-input>
      </el-form-item>

      <h4 class="h4_share">合作信息</h4>
      <el-form-item label="委托描述">
        <el-input
          v-model="form.delegationDescription"
          placeholder="请输入委托描述"
          type="textarea"
          :autosize="{ minRows: 2 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务方式">
        <el-input
          v-model="form.serviceMode"
          placeholder="请输入服务方式"
          type="textarea"
          :autosize="{ minRows: 2 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="合作描述">
        <el-input
          v-model="form.collaborationDescription"
          placeholder="请输入合作描述"
          type="textarea"
          :autosize="{ minRows: 2 }"
        ></el-input>
      </el-form-item>
    </el-form>

    <div class="button_box">
      <el-button :disabled="disabled" class="save button" @click="clickSave">保存</el-button>
      <div class="cancel button" @click="clickCancel">取消</div>
    </div>
  </div>
</template>
<script>
import { getCode } from '@/api/demand.js'
import { listById, serverApiAddClue, update } from '@/api/clue.js'
import Editorbar from '../../components/Editorbar.vue'
import uploadVideo from '../components/uploadVideo.vue'
import cos from '../../../../utils/cos.js'
import { getCityAll } from '@/api/demand'
// import UploadImage from './uploadImage.vue'
export default {
  components: { Editorbar, uploadVideo },
  props: {
    activeId: {
      type: String
    },
    // 获取保存唯一编号
    clueSmartNumber: {
      type: String
    }
  },
  data() {
    return {
      value: '',
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [], //区

      form: {
        clueSmartNumber: this.clueSmartNumber, //获取保存唯一编号
        date: [],
        associatePhone: '', //对接人联系方式
        associatePosition: '', //对接人职位
        budgetDescription: '', //预算描述
        budget: '', // 预算费用
        clueProjectRequirement: '', // 项目需求
        clueStage: '', // 项目状态
        city: '', //城市
        district: '',
        clueType: '', // 线索类型
        companyType: '', // 客户类型
        contactPersonList: [
          {
            associatePhone: '', // 对接人联系方式
            associatePosition: '', //对接人职位
            otherPosition: '', // 其他职位名称
            clueId: '', // 线索id
            customerContactPerson: '', // 客户对接人
            id: '', // 对接人id
            qq: '', // QQ号码
            weChatNumber: '' // 微信号码
          }
        ], // 客户信息对接人
        supplierRequirement: '有***案例优先', //供应商需求
        clueDetails: '', //线索详情
        clueEndTime: '', //线索结束时间
        clueImage: '', //	线索封面图
        clueName: '', //线索标题
        clueResource: '', // 线索来源
        delegationDescription:
          '需求企业委托智参中心匹配与链接优质潜在供应商，本需求线索需经过中心甄选且符合需求企业初步预期后，再行对接。', // 委托描述
        serviceMode:
          '由智参中心项目对接人员为需求方提供供应商提供产品资料和案例资料，前期解答需求方核心关切问题，经过需求方认可后即可直接对接 或 智参中心以需求方身份推广需求信息，由符合需求的服务商直接联络需求企业。', //服务方式
        clueChannelName: '', //渠道名称
        clueChannelPhone: '', //手机号码
        collaborationDescription:
          '在初步认可后，在推进合作阶段，前期可以付费进行定制POC或者技术验证，达到要求后即开展合作。', //合作描述
        clueNature: [], //线索性质 1->产品性质;2->产品选购
        clueOneDescription: '', //线索一句话描述
        clueStartTime: '', //线索开始时间
        companyId: '', //智参企业编号
        customerContactPerson: '', //客户对接人
        generalClueDiscount: 0, //普通用户线索折扣
        generalClueDiscountsPrice: 0, //普通用户线索优惠价
        generalClueDiscountsType: '', //普通用户线索优惠方式 1->折扣;2->优惠价
        generalCluePrice: 0, //普通用户线索值
        generalClueType: '', //普通用户线索费用类型 1->免费;2->付费
        generalFeeMethod: [], //普通用户费用方式 1->押金;2->线索费用
        generalPledgeDiscount: 0, //普通用户押金折扣
        generalPledgeDiscountsPrice: 0, //普通用户押金优惠价
        generalPledgeDiscountsType: '', //普通用户押金优惠方式 1->折扣;2->优惠价
        generalPledgePrice: 0, //普通用户押金值
        generalPledgeType: '', //普通用户押金费用类型 1->免费;2->付费
        partnerId: null, //合作伙伴编号
        province: '', //省份
        shareImage: '', //分享图片
        shareLinkTitle: '企业数智化转型的“专属参谋”', //分享链接标题
        supplierDescription: '', //供应商描述
        deposit: '', //押金
        clueCost: '', //线索费用'
        imageAndVideoResults: null,
        clueFiles: [], // 多个附件
        videoList: [], // 多个视频
        clueAttachmentImageList: [] // 多个图片
      },
      typeList: [], //线索类型
      stageList: [], //线索状态
      companyTypeList: [], //客户类型
      sourceList: ['智参运营', '专家引荐', '其它'], //线索来源
      zhiweiList: [], //客户职位
      updatePwdRules: {
        clueName: [{ required: true, message: '请输入项目标题', trigger: 'blur' }],
        clueImage: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        clueStage: [{ required: true, message: '请选择项目状态', trigger: 'blur' }],
        clueProjectRequirement: [{ required: true, message: '请输入项目需求', trigger: 'blur' }],
        clueStartTime: [{ required: true, message: '请选择开始日期', trigger: 'blur' }],
        clueType: [{ required: true, message: '请选择线索类型', trigger: 'blur' }],
        supplierRequirement: [{ required: true, message: '请输入供应商要求', trigger: 'blur' }],
        deposit: [{ required: true, message: '请输入押金费用', trigger: 'blur' }],
        clueCost: [{ required: true, message: '请输入线索费用', trigger: 'blur' }]
      },
      isClear: false,
      disabled: false, //点击保存禁用按钮
      clueFiles: [
        {
          name: ''
        }
      ],
      clueAttachmentImageList: [
        {
          name: ''
        }
      ]
    }
  },

  created() {
    // 获取线索类型  019
    this.getCode('034')
    this.getCode('032')
    this.getCode('019')
    this.getCode('035')
    this.incity()
    if (this.activeId) {
      this.listById()
    }
  },
  // watch: {
  //    // 使用深度监听（deep: true）来监听formData对象中content属性的变化
  //    form: {
  //     handler(newVal, oldVal) {
  //       // 检查content属性的长度
  //       if (newVal.clueProjectRequirement.length > 3000) {
  //         this.$message.warning('输入字数不能超过3000字')
  //         // 如果需要，可以截断多余的文字
  //         this.form.clueProjectRequirement = newVal.clueProjectRequirement.slice(0, 3000);
  //         console.log(this.form.clueProjectRequirement.length);
  //       }
  //     },
  //     // 深度监听
  //     deep: true,
  //   },
  // },
  //方法集合
  methods: {
    async getCode(data) {
      const { data: res } = await getCode({ codeType: data })
      if (res.resultCode == 200) {
        if (data == '034') {
          this.stageList = res.data
        }
        if (data == '032') {
          this.companyTypeList = res.data
        }
        if (data == '019') {
          this.typeList = res.data
        }
        if (data == '035') {
          this.zhiweiList = res.data
        }
      }
    },
    // 只能输入数字
    handleInput(value) {
      let dat =
        value
          .replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || '' // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      this.form.budget = dat
    },
    // 押金费用限制
    handleInputDeposit(value) {
      let dat =
        value
          .replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || '' // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      this.form.deposit = dat
    },
    // 线索费用限制
    handleInputClueCost(value) {
      let dat =
        value
          .replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || '' // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      this.form.clueCost = dat
    },
    lookClick(val) {
      this.form.clueAttachmentImageList = val
    },
    saveClick(val) {
      this.form.clueAttachmentImageList = val
    },
    //城市
    async incity() {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //选择城市
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          this.form.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.form.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.form.city == o.name) {
          this.form.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
    },
    //线索详情
    async listById() {
      const { data: res } = await listById({ id: this.activeId })
      if (res.resultCode == 200) {
        this.form = res.data
        this.handleData(res)
        if (!res.data.contactPersonList) {
          this.form.contactPersonList = [
            {
              associatePhone: '', // 对接人联系方式
              associatePosition: '', //对接人职位
              otherPosition: '', // 其他职位名称
              clueId: '', // 线索id
              customerContactPerson: '', // 客户对接人
              id: '', // 对接人id
              qq: '', // QQ号码
              weChatNumber: '' // 微信号码
            }
          ]
        }
        //线索性质
        if (this.form.clueNature) {
          this.form.clueNature = this.form.clueNature.split(',')
        } else {
          this.form.clueNature = []
        }
        //费用方式
        if (this.form.generalFeeMethod) {
          this.form.generalFeeMethod = this.form.generalFeeMethod.split(',')
        } else {
          this.form.generalFeeMethod = []
        }
        // if (this.form.clueStartTime) {
        //   this.$set(this.form, 'date', [this.form.clueStartTime, this.form.clueEndTime])
        // }
        if (!this.shareImage) {
          this.shareImage = ''
        }
        if (res.data.clueAttachmentImageList) {
          // console.log(res.data.clueAttachmentImageList,'clueAttachmentImageList');
        }
      }
    },
    // 数据处理
    handleData(res) {
      if (res.data.clueFiles) {
        //有附件时
        this.clueFiles = []
        res.data.clueFiles.forEach((el) => {
          this.clueFiles.push({
            name: el
          })
        })
      } else {
        this.form.clueFiles = []
      }
      if (res.data.clueAttachmentImageList) {
        //有附件时
        this.clueAttachmentImageList = []
        res.data.clueAttachmentImageList.forEach((el) => {
          this.clueAttachmentImageList.push({
            name: el
          })
        })
      } else {
        this.form.clueAttachmentImageList = []
      }
      // if (res.data.clueAttachmentImageList) {
      //   this.form.clueAttachmentImageList = res.data.clueAttachmentImageList
      // } else {
      //   this.form.clueAttachmentImageList = []
      // }
    },
    // 数据处理
    dislodge() {
      if (this.clueFiles.length > 0) {
        if (!this.clueFiles[0].name) {
          //附件
          this.form.clueFiles = null
        } else {
          this.form.clueFiles = this.clueFiles.map((item) => item.name)
        }
      }
      if (this.clueAttachmentImageList.length > 0) {
        if (!this.clueAttachmentImageList[0].name) {
          this.form.clueAttachmentImageList = null
        } else {
          this.form.clueAttachmentImageList = this.clueAttachmentImageList.map((item) => item.name)
        }
      }
      if (this.form.clueProjectRequirement) {
        let text = this.form.clueProjectRequirement.replace(/^<p>/, '')
        this.form.clueProjectRequirement = text.replace(/<\/p>$/, '')
      }
    },
    //新增附件
    addUpload() {
      this.clueFiles.push({
        name: ''
      })
    },
    //删除行业动态附件
    delUpload(index) {
      this.clueFiles.splice(index, 1)
    },

    //新增图片
    addAttachmentImage() {
      this.clueAttachmentImageList.push({
        name: ''
      })
    },
    //删除图片
    delAttachmentImage(index) {
      this.clueAttachmentImageList.splice(index, 1)
    },

    //新增对接人
    addCustomerContactPerson() {
      this.form.contactPersonList.push({
        associatePhone: '', // 对接人联系方式
        associatePosition: '', //对接人职位
        clueId: '', // 线索id
        customerContactPerson: '', // 客户对接人
        id: '', // 对接人id
        qq: '', // QQ号码
        weChatNumber: '' // 微信号码
      })
    },
    //删除对接人
    delCustomerContactPerson(index) {
      this.form.contactPersonList.splice(index, 1)
    },

    //上传行业动态pdf
    async beforeUploadprods(file) {
      this.file = file
      this.filename = file.name
      let format = file.name.split('.')[1]

      let formatList = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']

      const extension = formatList.indexOf(format)
      if (extension == -1) {
        this.$message.warning('上传模板不支持此格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传行业动态
    uploadprods(params, index) {
      /* 直接调用 cos sdk 的方法 */
      cos
        .uploadFile({
          Bucket: 'ginseng-wisdom-1300257084' /* 填写自己的 bucket，必须字段 */,
          Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */,
          Key:
            'system_img/' +
            params.file.name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
          Body: params.file, // 上传文件对象
          SliceSize:
            1024 *
            1024 *
            500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
        })
        .then((res) => {
          if (res.statusCode === 200) {
            this.clueFiles[index].name = res.Location
          }
        })
        .catch((err) => {
          console.log('上传出错', err)
        })
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.shareImage = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.shareImage = ''
    },
    // 保存
    clickSave() {
      this.disabled = true
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.dislodge()
          if (this.form.clueNature.length > 0) {
            this.form.clueNature = this.form.clueNature.toString()
          }
          if (this.form.generalFeeMethod.length) {
            this.form.generalFeeMethod = this.form.generalFeeMethod.toString()
          }

          if (this.form.id == null) {
            serverApiAddClue(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.disabled = false
                this.$message.success('新增成功')
                this.$router.go(-1)
              } else {
                this.disabled = false
                this.$message.error(response.data.message)
              }
            })
          } else {
            update(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.disabled = false
                this.$message.success('编辑成功')
                this.$router.go(-1)
              } else {
                this.disabled = false
                this.$message.error(response.message)
              }
            })
          }
        } else {
          this.$message.warning('请将信息填写完整！')
          this.disabled = false
        }
      })
    },
    // 取消
    clickCancel() {
      this.$router.go(-1)
    },
    //多个图片上传显示
    uploadOneImg(val) {
      this.form.clueImage = val
    },
    // 移除
    removeImg(val) {
      this.form.clueImage = ''
    },
    //多个图片上传显示
    uploadAttachmentImage(val) {
      this.form.clueAttachmentImageList.push(val)
    },
    // 移除
    removeAttachmentImage(val) {
      this.form.clueAttachmentImageList.forEach((item, index) => {
        if (item.includes(val.name)) {
          this.form.clueAttachmentImageList.splice(index, 1)
        }
      })
    },

    //视频上传
    uploadOneImgvideo(val) {
      this.form.videoList = val
    },
    removeImgvideo(val) {
      this.form.videoList = val
    }
  }
}
</script>
<style scoped lang="less">
.formBox {
  padding-top: 24px;
  width: 100%;
  height: 100%;
  .formBox_header {
    margin: 0px 20px 20px 30px;
    padding-left: 5px;
    border-left: 4px solid #4e93fb;
  }
  .img_tips {
    font-size: 12px;
    color: #6ba1ff;
  }
  .h4_share {
    margin: 50px 50px 30px 30px;
    padding-left: 5px;
    border-left: 4px solid #4e93fb;
    color: #333333;
    font-size: 18px;
    font-weight: bold;
  }
  .tiket_flex {
    display: flex;
  }
  .ticket_Type {
    width: 600px;
    // height: 980px;
    padding: 20px 0px;
    border: 1px dashed #c4c4c4;
    border-radius: 2px;
    padding-left: 20px;
    min-height: 100px;
    .add_information {
      margin-left: 40px;
      width: 433.54px;
      min-height: 100px;
      border: 1px dashed #c4c4c4;
      border-radius: 2px;
      margin-bottom: 15px;
      .online_conference {
        .online_color {
          color: #999999;
        }
      }
      .online_conferences {
        margin-top: 16px;
        width: 447.76px;
        height: 305.17px;
        border: 1px dashed #c4c4c4;
        border-radius: 2px;
        margin-left: 120px;
        margin-bottom: 10px;
        .online_color {
          color: #999999;
        }
      }
    }
  }

  .inputBox {
    height: 40px;
    display: flex;
  }
  .general_inputs {
    width: 585px;
    border: 1px solid #d4d4d4;
    padding: 14px 20px;
    .input_flex {
      display: flex;
      justify-content: space-between;
      padding: 7px 0px;
      span {
        padding-left: 15px;
        font-size: 20px;
        cursor: pointer;
      }
      .el_buttom {
        position: relative;
        left: -110px;
      }
    }
  }

  /deep/.el-input {
    width: 600px;
    height: 40px;
  }

  .labenBox {
    width: 120px;
    display: flex;

    margin-right: 12px;

    .label {
      color: #333333;
      font-size: 14px;
      margin-right: 2px;
    }
    .span {
      color: #f05252;
    }
  }

  .logo_box {
    display: flex;
    .label_box {
      display: flex;
      margin-right: 12px;
      width: 120px;
      justify-content: flex-end;
      .logo_label {
        color: #333333;
        font-size: 14px;
        margin-right: 2px;
      }
      .span {
        color: #f05252;
      }
    }
  }

  .cover {
    display: flex;
  }

  .picker {
    width: 240px !important;
  }

  /deep/.el-radio__input {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__inner {
    width: 18px;
    height: 18px;
  }
  /deep/.el-radio__label {
    font-size: 14px;
    color: #333333;
  }

  .editInformation {
    margin-top: 40px;
    margin-left: 58px;
    color: #999999;
    font-size: 14px;

    .item {
      margin-bottom: 6px;
    }
  }
  .button_box {
    width: 100%;
    // height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ececec;
    padding-top: 15px;
    padding-bottom: 55px;
    .send {
      background-color: #f79646;
      color: #fff;
      cursor: pointer;
    }
    .preview {
      background-color: #37cc7e;
      color: #fff;
      cursor: pointer;
    }
    .save {
      background-color: #4e93fb;
      color: #fff;
      cursor: pointer;
    }
    .cancel {
      background-color: #fff;
      color: #4e93fb;
      cursor: pointer;
      border: 1px solid #4e93fb;
    }
    .button {
      width: 112px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;
    }
  }
  .editBarBox {
    width: 100%;
    display: flex;
    position: relative;
    z-index: 9;
    .editbar_text {
      width: 130px;
      display: flex;
      justify-content: flex-end;
      .text_a {
        margin-right: 2px;
      }
      .span {
        margin-right: 12px;
        color: #f05252;
      }
    }

    .editBar {
      // width: 686px;
      // width: 100%;
      width: 598.31px;
      height: 437px;
    }
  }
}
.inline_form_box {
  display: flex;
  // align-items: center;
  ::v-deep .el-input {
    color: #448aff;
    background: #ecf3ff;
    border-color: #b4d0ff;
    border: none;
    width: 217px;
  }
}
.informationType {
  ::v-deep .el-input__inner,
  ::v-deep .el-input {
    width: 220px;
  }
}
.cover {
  display: flex;
  flex-direction: column;
}
.thumbnail {
  display: flex;

  color: #bebebe;
}
.thumbnail img {
  width: 143.23px;
  height: 81.63px;
  border-radius: 4px;
}

.thumbnail div {
  margin-right: 20px;
}
.thumbnail_box div {
  display: flex;
  flex-direction: column;
  height: 30px;
}

::v-deep .el-button--primary.is-plain {
  color: #448aff;
  background: #ecf3ff;
  border-color: #b4d0ff;
  border: none;
}
::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}
::v-deep .editor .text {
  border: 1px solid #ccc;
  min-height: 390px;
  max-height: 390px;
}
::v-deep .w-e-text-container .w-e-text {
  max-height: 390px;
}
::v-deep .txtree .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 600px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 600px;
  vertical-align: bottom;
  font-size: 14px;
}

::v-deep .original .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 180px;
  margin-bottom: 20px;
}
::v-deep .el-upload-dragger {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.editorbar_box {
  width: 50%;
  min-width: 600px;
  min-height: 400px;
  .editBar {
    width: 100%;
    // height: 487px;
  }
}
.el-upload__text {
  color: #ccc;
}
::v-deep .upload .el-form-item__content {
  display: flex;
  align-items: center;
}
.el-icon-circle-plus {
  font-size: 30px;
  margin-left: 10px;
}
::v-deep .name_form .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}
.file_flex {
  margin-bottom: 10px;
  width: 600px;
  display: flex;
  justify-content: flex-start;
}
.image_flex {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
::v-deep .h4_share_item {
  margin-bottom: 0;
  .el-form-item__label {
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
